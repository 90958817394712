import React from "react";

const Header = () => {
  return (
    <>
      <div class="container" style={{textAlign: "center"}}>
        <h1>Eastvantage React JS Assignment</h1>
        <hr></hr>
      </div>
    </>
  );
};

export default Header;
